import React from "react"
import {injectIntl} from "react-intl"
import SEO from "../components/common/layout/seo"
import LayoutWatches from "../components/common/layout/LayoutWatches"
import Loadable from "@loadable/component"
import Header from "../components/homeV3/Header";
import ProsegurConnected from "../components/homeV3/ProsegurConnected";
import WatchFeatures from "../components/homeV3/WatchFeatures";
import LoveReplacement from "../components/homeV3/LoveReplacement";
import Watch from "../components/homeV3/Watch";
import OneYearIncluded from "../components/homeV3/OneYearIncluded";
import FAQSCard from "../components/commonV3/faqs/FAQSSection";
import Press from "../components/commonV3/Press";

const BuyFixedBottom = Loadable(() => import("../components/homeV3/BuyFixedBottom"))

const TeleasistenciaPage = ({ location, intl }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_home_teleasistencia" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_home_teleasistencia" })
//

  return (
      <>
    <LayoutWatches {...{ location }}>
      <SEO title={SEOTitle} description={SEODescription} />
      <Header {...{ location, title: intl.formatMessage({id: "teleasistenciaHomeTitle"}), description: intl.formatMessage({id: "teleasistenciaHomeDescription"}) }}/>
      <ProsegurConnected/>
      <WatchFeatures/>
      <LoveReplacement/>
      <Watch {...{ location }}/>
      <OneYearIncluded/>
      <FAQSCard/>
      <Press/>
      <BuyFixedBottom  {...{ location }}/>
    </LayoutWatches>
</>
  )
}

export default injectIntl(TeleasistenciaPage)
